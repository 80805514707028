export enum ThemeVersion {
  Contrast = 'Contrast',
  Default = 'Default',
}

export enum FontSize {
  L = 'L',
  M = 'M',
  S = 'S',
}
