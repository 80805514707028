import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { theme } from 'theme';
import { Button, Heading, Paragraph } from 'common/components';
import bots404 from 'assets/Images/Rest/Bots404.png';
import { Layout } from 'common/components/Layout';

export const FallbackView = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Styled.Container>
        <img src={bots404} alt="Robot with 404 info" />
        <Heading level="h2" a11yLevel="h1">
          Something is missing...
        </Heading>
        <Paragraph color={theme.colors.white[1]}>{"We can't seem to find the page you're looking for."}</Paragraph>
        <Button variant="outlined" onClick={() => navigate('/')}>
          Go home
        </Button>
      </Styled.Container>
    </Layout>
  );
};

const Styled = {
  Container: styled.div`
    width: fit-content;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
    margin: 0 auto;
  `,
};
