import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useGlobalContext } from 'context';
import { Flex, Gradient, Heading, Paragraph } from 'common/components';
import { useResponsiveVariant } from 'common/hooks';

type ParagraphImageSectionProps = {
  id: string;
  preTitle: string;
  title: string;
  content: string;
  image: string;
  index: number;
};

enum AnimationStatuses {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  PROGRESS = 'PROGRESS',
}

type ImageWrapperProps = {
  position: 'right' | 'left';
  animationStatus: AnimationStatuses;
  isVerySmallMobile: boolean;
};

export const ParagraphImageSection = ({ id, preTitle, title, content, image, index }: ParagraphImageSectionProps) => {
  const [animationStatus, setAnimationStatus] = useState(AnimationStatuses.BEFORE);
  const ref = useRef<HTMLElement>(null);
  const { setSectionsRefs } = useGlobalContext();
  const { isTabletOrMobile, isMobile, isSmallTablet, isVerySmallMobile } = useResponsiveVariant();

  const scrollListener = useCallback(() => {
    const screenTop = document.scrollingElement?.scrollTop;
    if (screenTop && ref.current) {
      const screenBottom = screenTop + innerHeight;
      const refTop = ref.current.getBoundingClientRect().top + scrollY + 150;
      const refBottom = ref.current.getBoundingClientRect().bottom + scrollY - 150;

      if (refBottom > screenTop && refTop < screenBottom && animationStatus === AnimationStatuses.BEFORE) {
        setAnimationStatus(AnimationStatuses.AFTER);
      }
    }
  }, [animationStatus]);

  useEffect(() => {
    document.addEventListener('scroll', scrollListener);
  }, [scrollListener]);

  useEffect(() => {
    if (ref.current) {
      setSectionsRefs((prev) => [...prev.filter((item) => item.name !== id), { name: id, ref }]);
    }
  }, [ref, id, setSectionsRefs]);

  return (
    <Styled.Container ref={ref}>
      <Flex gap={isSmallTablet ? 30 : isTabletOrMobile ? 60 : 100} direction={isMobile ? 'column' : 'row'}>
        {!!(index % 2) && !isMobile && (
          <Styled.ImageWrapper position="left" animationStatus={animationStatus} isVerySmallMobile={isVerySmallMobile}>
            <img src={image} alt={title} />
          </Styled.ImageWrapper>
        )}
        <Flex gap={16} direction="column" width={isVerySmallMobile ? '95vw' : '476px'} align="flex-start">
          <span>{preTitle}</span>
          <Heading level="h4" a11yLevel="h3">
            <Gradient>{title}</Gradient>
          </Heading>
          <Paragraph>{content}</Paragraph>
        </Flex>
        {(!(index % 2) || isMobile) && (
          <Styled.ImageWrapper position="right" animationStatus={animationStatus} isVerySmallMobile={isVerySmallMobile}>
            <img src={image} alt={title} />
          </Styled.ImageWrapper>
        )}
      </Flex>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.section`
    scroll-margin-top: 100px;
    padding: 0 2vw;
  `,

  ImageWrapper: styled.div<ImageWrapperProps>`
    background: linear-gradient(103.18deg, #f6f6f6 -4.11%, #b784d7 106.6%);
    position: relative;
    border-radius: 24px;
    width: ${({ isVerySmallMobile }) => (isVerySmallMobile ? '96vw' : '470px')};
    height: ${({ isVerySmallMobile }) => (isVerySmallMobile ? '96vw' : '470px')};
    overflow: hidden;

    img {
      max-width: 672px;
      max-height: 420px;
      position: absolute;
      height: 420px;
      width: 672px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      border-radius: 16px;

      ${({ position }) =>
        position === 'left'
          ? css`
              left: -672px;
            `
          : css`
              right: -672px;
            `}

      ${({ animationStatus, position }) =>
        animationStatus === AnimationStatuses.AFTER &&
        (position === 'left'
          ? css`
              opacity: 1;
              transform: translateY(-50%) translateX(438px);
              transition:
                transform 1s ease-in-out,
                opacity 1s ease-in-out;
            `
          : css`
              opacity: 1;
              transform: translateY(-50%) translateX(-438px);
              transition:
                transform 1s ease-in-out,
                opacity 1s ease-in-out;
            `)}
    }
  `,
};
