import { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

import { ParagraphSizes, paragraphs, theme } from 'theme';
import { Devices } from 'types';
import { useDeviceType } from 'common/hooks';
import { FontSize, useA11y } from 'common/components/A11yControls';
import { calculateA11yFontSize } from 'common/utils/calculateA11yFontSize';

type ParagraphProps = {
  align?: 'unset' | 'left' | 'right' | 'center';
  color?: string;
  size?: ParagraphSizes;
};

export const Paragraph = ({
  children,
  align = 'unset',
  color = theme.colors.white[0],
  size = 'm',
}: PropsWithChildren<ParagraphProps>) => {
  const deviceType = useDeviceType();
  const { fontSize } = useA11y();

  return (
    <Styled.Paragraph align={align} color={color} size={size} deviceType={deviceType} fontSize={fontSize}>
      {children}
    </Styled.Paragraph>
  );
};

const Styled = {
  Paragraph: styled.p<Required<ParagraphProps> & { deviceType: Devices; fontSize: FontSize }>`
    padding: 0;
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    color: ${({ color }) => color};
    font-size: ${({ size, deviceType, fontSize }) =>
      calculateA11yFontSize(paragraphs[size][deviceType].size, fontSize)};
    font-weight: ${({ size, deviceType }) => paragraphs[size][deviceType].weight};
    text-align: ${({ align }) => align};
  `,
};
