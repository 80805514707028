import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { CrossIcon, HamburgerMenu, SkytradingLogo } from 'assets/SVGs';
import { NavList } from 'common/components/NavBar/components';
import { A11yControls } from 'common/components/A11yControls';

type NavBarMobileProps = {
  displayLogo?: boolean;
};

export const NavBarMobile = ({ displayLogo = true }: NavBarMobileProps) => {
  const [isNavListExpanded, setIsNavListExpanded] = useState(false);
  const navigate = useNavigate();

  return (
    <Styled.Container isNavListExpanded={isNavListExpanded}>
      <Styled.InnerContainer>
        {displayLogo && (
          <button onClick={() => navigate('/')} aria-label="Go to home page">
            <SkytradingLogo />
          </button>
        )}
        <A11yControls />
        <button
          onClick={() => setIsNavListExpanded((prev) => !prev)}
          aria-label={isNavListExpanded ? 'Close navigation menu' : 'Expand navigation menu'}
        >
          {isNavListExpanded ? <CrossIcon /> : <HamburgerMenu />}
        </button>
      </Styled.InnerContainer>
      {isNavListExpanded && <NavList isMobile />}
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.nav<{ isNavListExpanded: boolean }>`
    background-color: rgba(22, 24, 36, 0.16);
    backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 16px;
    align-items: flex-start;
    z-index: ${({ theme }) => theme.zIndexes.layoutBelow};
    position: fixed;
    top: 0;
    height: ${({ isNavListExpanded }) => (isNavListExpanded ? '100%' : 'auto')};
  `,
  InnerContainer: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
};
