import styled from 'styled-components/macro';

import { SkytradingLogo } from 'assets/SVGs';
import { Flex, FooterSectionType, PartnersLogos } from 'common/components';
import { FooterInfoWithMedia, FooterSection, FooterCopyrightAndTerms } from 'common/components/Footer/components';

type DesktopFooterProps = {
  footerSections: FooterSectionType[];
};

export const DesktopFooter = ({ footerSections }: DesktopFooterProps) => (
  <Flex direction="column" gap={120}>
    <PartnersLogos showTrustedLabel />
    <Styled.Container>
      <Styled.Info>
        <SkytradingLogo />
        <FooterInfoWithMedia />
      </Styled.Info>
      {footerSections.map((item) => (
        <FooterSection {...item} key={item.name} />
      ))}
    </Styled.Container>
    <FooterCopyrightAndTerms />
  </Flex>
);

const Styled = {
  Container: styled.footer`
    width: 100%;
    display: flex;
    padding: 0 8vw;

    & > * {
      width: 25%;
    }
  `,

  Info: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,

  RobotWrapper: styled.div`
    align-self: flex-end;
    margin: 0 10% 0 auto;
  `,
};
