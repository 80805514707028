import { PropsWithChildren, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';

import { HeadingLevels, headings } from 'theme';
import { Devices } from 'types';
import { useDeviceType } from 'common/hooks';
import { FontSize, useA11y } from 'common/components/A11yControls';
import { calculateA11yFontSize } from 'common/utils/calculateA11yFontSize';

interface HeadingProps extends HTMLAttributes<HTMLElement> {
  level: HeadingLevels;
  a11yLevel: HeadingLevels;
  align?: 'unset' | 'left' | 'right' | 'center';
  color?: string;
  margin?: number;
}

export const Heading = ({ children, ...props }: PropsWithChildren<HeadingProps>) => {
  const deviceType = useDeviceType();
  const { fontSize } = useA11y();

  return (
    <Styled.Text as={props.a11yLevel} {...props} deviceType={deviceType} fontSize={fontSize}>
      {children}
    </Styled.Text>
  );
};

const Styled = {
  Text: styled.div<HeadingProps & { deviceType: Devices; fontSize: FontSize }>`
    margin: ${({ margin = 0 }) => `0 0 ${margin}px`};
    color: ${({ theme, color = theme.colors.white[0] }) => color};
    font-size: ${({ level, deviceType, fontSize }) =>
      calculateA11yFontSize(headings[level][deviceType].size, fontSize)};
    font-weight: ${({ level, deviceType }) => headings[level][deviceType].weight};
    text-align: ${({ align = 'unset' }) => align};
  `,
};
