import styled from 'styled-components/macro';

import { Flex, Heading, Paragraph } from 'common/components';
import EuropeanFoundBanner from 'assets/Images/Rest/EuropeanFoundBanner.png';
import { Layout } from 'common/components/Layout';

export const ParpView = () => (
  <Layout>
    <Styled.Container>
      <Flex direction="column" gap={24} align="flex-start">
        <Heading level="h6" a11yLevel="h6">
          Algorithmic Trading Platform - backtester strategy application for Equity, Forex and Crypto trading
        </Heading>
        <Paragraph color="#CDCED0">
          Under The European Regional Development Fund, Operational Program Eastern Poland, Action 1.1 Starting
          platforms for new ideas Submeasure 1.1.2 Development of startups in Eastern Poland under contract no.
          POPW.01.01.02-20-0029/21
        </Paragraph>
        <img src={EuropeanFoundBanner} style={{ placeSelf: 'center' }} alt="European found banner" />
        <Paragraph color="#CDCED0">
          The aim of the project is to build investment strategy backtester for Equity, Forex and Crypto trading. Thanks
          to the built-in machine learning module, the application automatically improves strategies and shows optimal
          settings to improve results. Program will be used to conduct automated research on the influence of various
          factors on the value of the stock. In visual and numerical interpretations of the results will be available on
          the platform. To create an investment strategy will used backtest module that uses historical data. By using
          certain parameters the module is able to adapt to the conditions prevailing on the stock exchange.
        </Paragraph>
        <Paragraph color="#CDCED0">Total project value: PLN 1 189 734.00</Paragraph>
      </Flex>
    </Styled.Container>
  </Layout>
);

const Styled = {
  Container: styled.div`
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 200px;
    margin: 180px auto;
  `,
};
