import styled, { css } from 'styled-components';

import { Button, FontSize, ThemeVersion, useA11y } from 'common/components';
import { useResponsiveVariant } from 'common/hooks';
import { ContrastIcon } from 'assets/SVGs';

const sizes = {
  S: '12px',
  M: '16px',
  L: '20px',
};

export const A11yControls = () => {
  const { fontSize, themeVersion, setFontSize, switchThemeVersion } = useA11y();
  const { isMobile } = useResponsiveVariant();

  return (
    <Styled.Container isMobile={isMobile}>
      <Styled.Button
        variant="ghost"
        aria-label="Reduce font size"
        onClick={() => setFontSize(FontSize.S)}
        selected={fontSize === FontSize.S}
      >
        <Styled.Text size="S">a</Styled.Text>
      </Styled.Button>
      <Styled.Button
        variant="ghost"
        aria-label="Set default font size"
        onClick={() => setFontSize(FontSize.M)}
        selected={fontSize === FontSize.M}
      >
        <Styled.Text size="M">a</Styled.Text>
      </Styled.Button>
      <Styled.Button
        variant="ghost"
        aria-label="Increase font size"
        onClick={() => setFontSize(FontSize.L)}
        selected={fontSize === FontSize.L}
      >
        <Styled.Text size="L">a</Styled.Text>
      </Styled.Button>
      <Styled.Button
        variant="ghost"
        aria-label="Increase website contrast"
        onClick={switchThemeVersion}
        selected={themeVersion === ThemeVersion.Contrast}
      >
        <Styled.ContrastIcon />
      </Styled.Button>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.section<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.purple[4]};
    transform: ${({ isMobile }) => `scale(${isMobile ? 0.8 : 1})`};
  `,

  Text: styled.span<{ size: 'S' | 'M' | 'L' }>`
    font-size: ${({ size }) => sizes[size]};
    font-weight: 400;
    display: flex;
    justify-content: flex-end;
  `,

  ContrastIcon: styled(ContrastIcon)`
    width: 100%;
    height: 100%;
  `,

  Button: styled(Button)<{ selected: boolean }>`
    padding: 6px 12px;
    border-radius: 0;

    :first-child {
      border-radius: 10px 0 0 10px;
    }
    :last-child {
      border-radius: 0 10px 10px 0;
    }

    :hover {
      background-color: ${({ theme }) => theme.colors.purple[3]};
    }

    ${({ selected }) =>
      selected &&
      css`
        background-color: ${({ theme }) => theme.colors.purple[2]};
      `}
  `,
};
