/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { FontSize, ThemeVersion } from 'common/components/A11yControls/types';

const A11yContext = createContext({
  themeVersion: ThemeVersion.Default,
  fontSize: FontSize.M,
  switchThemeVersion: () => {},
  setFontSize: (fontSize: FontSize) => {},
});

export const A11yProvider = ({ children }: PropsWithChildren) => {
  const [themeVersion, setThemeVersion] = useState(ThemeVersion.Default);
  const [fontSize, setFontSize] = useState(FontSize.M);

  const switchThemeVersion = () =>
    setThemeVersion((prev) => (prev === ThemeVersion.Default ? ThemeVersion.Contrast : ThemeVersion.Default));

  return (
    <A11yContext.Provider value={{ themeVersion, fontSize, switchThemeVersion, setFontSize }}>
      {children}
    </A11yContext.Provider>
  );
};

export const useA11y = () => {
  const data = useContext(A11yContext);

  return data;
};
