import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import styled from 'styled-components/macro';
import axios from 'axios';

import { Button, Flex, Gradient, Heading, Paragraph } from 'common/components';
import { Layout } from 'common/components/Layout';
import * as animationData from 'assets/Lotties/confetti.json';
import { theme } from 'theme';

type SubscribedViewProps = {
  origin: 'newsletter' | 'waitlist';
};

export const SubscribedView = ({ origin }: SubscribedViewProps) => {
  const [isAnimationShown, setIsAnimationShown] = useState(true);
  const navigate = useNavigate();

  const confimOrigin = useCallback(
    async (queryParameters: URLSearchParams) => {
      const confirmEmail = queryParameters.get('email_address');
      const token = queryParameters.get('code');
      if (!confirmEmail || !token) return;
      try {
        if (origin === 'newsletter') {
          await axios.get(`https://skytrading.io/api/confirm?email_address=${confirmEmail}&code=${token}`);
        } else {
          await axios.get(`https://skytrading.io/api/confirmWaitlist?email_address=${confirmEmail}&code=${token}`);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [origin],
  );

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    confimOrigin(queryParameters);
  }, [confimOrigin]);

  const defaultLottieOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Layout>
      {isAnimationShown && (
        <Styled.Confetti>
          <Lottie
            options={defaultLottieOptions}
            isClickToPauseDisabled
            eventListeners={[{ eventName: 'complete', callback: () => setIsAnimationShown(false) }]}
          />
        </Styled.Confetti>
      )}
      <Styled.Container>
        <Heading level="h2" a11yLevel="h2">
          <Gradient>
            {origin === 'newsletter'
              ? 'Thank you for subscribing to our newsletter!'
              : 'Thanks for joining our waitlist!'}
          </Gradient>
        </Heading>
        {origin === 'newsletter' ? (
          <Paragraph size="l" color={theme.colors.grey[0]}>
            We are glad you joined us! We will send you regular information about news, promotions and interesting
            facts.
          </Paragraph>
        ) : (
          <Flex direction="column" align="flex-start">
            <Paragraph size="l" color={theme.colors.grey[0]}>
              As a waitlist subscriber, you will receive:
            </Paragraph>
            <Styled.List>
              <li>
                <Paragraph size="l" color={theme.colors.grey[0]}>
                  Early access to AI tool
                </Paragraph>
              </li>
              <li>
                <Paragraph size="l" color={theme.colors.grey[0]}>
                  Discounts and bonuses
                </Paragraph>
              </li>
              <li>
                <Paragraph size="l" color={theme.colors.grey[0]}>
                  Updates
                </Paragraph>
              </li>
            </Styled.List>
          </Flex>
        )}
        <Button variant="outlined" onClick={() => navigate('/')} disabled={isAnimationShown}>
          Go home
        </Button>
      </Styled.Container>
    </Layout>
  );
};

const Styled = {
  Container: styled.div`
    width: 60vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
  `,

  Confetti: styled.div`
    position: absolute;
    top: 0;
    left: 0;
  `,

  List: styled.ul`
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
};
