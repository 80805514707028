import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Flex, Paragraph } from 'common/components';
import EuropeanFoundBanner from 'assets/Images/Rest/EuropeanFoundBanner.png';

export const FooterCopyrightAndTerms = () => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" width="100%" align="flex-start" gap={20}>
      <Styled.EndContainer>
        <Paragraph color="#E1E1E1">Copyright ©2024 SkyTrading</Paragraph>
        <a onClick={() => navigate('/terms')}>
          <Paragraph color="#E1E1E1">Terms and conditions</Paragraph>
        </a>
      </Styled.EndContainer>
      <Styled.ParpContainer>
        <img src={EuropeanFoundBanner} style={{ placeSelf: 'center' }} alt="European found banner" />
      </Styled.ParpContainer>
    </Flex>
  );
};

const Styled = {
  EndContainer: styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    padding: 0 8vw 0 8vw;
  `,

  ParpContainer: styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
  `,
};
