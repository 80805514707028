import styled from 'styled-components/macro';

import { Flex, Heading, Paragraph } from 'common/components';
import { Layout } from 'common/components/Layout';

export const DisclamerView = () => (
  <Layout>
    <Styled.Container>
      <Flex direction="column" gap={24} align="flex-start">
        <Heading level="h6" a11yLevel="h6">
          Disclamer
        </Heading>
        <Flex direction="column" gap={16} align="flex-start">
          <Heading level="h7" a11yLevel="h7" color="#CDCED0">
            No Investment Advice Provided
          </Heading>
          <Paragraph color="#CDCED0">
            SkyTrading.io is not a regulated entity. Trading financial instruments involves substantial risks and past
            performance is no guarantee of future results. The profits shown in the product screenshots are for preview
            purposes only. Only undertake bot trading if you are knowledgeable or consult a qualified financial advisor.
            SkyTrading.io is not responsible for any loss or damage, total or partial, resulting from transactions using
            our software, as well as direct and indirect damages. The content on the platform is created by members of
            the SkyTrading.io community and does not constitute advice or recommendations from SkyTrading.io. The
            presented profits are not a guarantee of future performance. By using SkyTrading.io, you acknowledge and
            accept the inherent risks involved in trading financial instruments and agree to hold SkyTrading.io harmless
            from any liabilities or losses incurred. Before using our software or engaging in any trading activity, it
            is necessary to read our terms and conditions.
          </Paragraph>
        </Flex>
      </Flex>
    </Styled.Container>
  </Layout>
);

const Styled = {
  Container: styled.div`
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 200px;
    margin: 180px auto;
  `,
};
