import { useState } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'theme';
import { Button, Checkbox, Gradient, Heading, Paragraph } from 'common/components';
import { CrossIcon } from 'assets/SVGs';
import { sendApiRequest } from 'common/utils';

type WaitlistPopupProps = {
  email: string;
  setIsPopupShown: (isShown: boolean) => void;
  setEmail: (email: string) => void;
};

export const WaitlistPopup = ({ email, setIsPopupShown, setEmail }: WaitlistPopupProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setEmail('');
    setIsPopupShown(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (isChecked) {
      await sendApiRequest({
        url: 'https://skytrading.io/api/subscribe',
        data: { email },
        method: 'POST',
      });
    }

    setEmail('');
    setIsLoading(false);
    setIsPopupShown(false);
  };

  return (
    <Styled.Container>
      <Styled.Button variant="ghost" onClick={handleClose}>
        <CrossIcon />
      </Styled.Button>
      <Heading level="h4" a11yLevel="h4">
        <Gradient>Check your mail</Gradient>
      </Heading>
      <Paragraph color={theme.colors.grey[0]} size="l">
        We have sent you an email confirming your entry. Accept it so that we can add you to waitlist.
      </Paragraph>
      <Checkbox
        label={
          <Paragraph size="xxl" color={theme.colors.grey[0]}>
            I want to join newsletter too.
          </Paragraph>
        }
        name="newsletter"
        checked={isChecked}
        onChange={() => setIsChecked((prev) => !prev)}
      />
      <Button variant="primary" size="L" customWidth="100px" onClick={handleSubmit} loading={isLoading}>
        Join
      </Button>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
    background-color: #0e0e10;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 550px;
    max-width: 100vw;
    height: 450px;
    padding: 64px;
    gap: 24px;
    border-radius: 64px;
    z-index: ${({ theme }) => theme.zIndexes.modal};

    & > button {
      align-self: flex-end;
    }
  `,

  Button: styled(Button)`
    position: absolute;
    top: 24px;
    right: 24px;
  `,
};
