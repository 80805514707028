import { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

import { ThemeVersion, useA11y } from 'common/components/A11yControls';

export const Gradient = ({ children }: PropsWithChildren) => {
  const { themeVersion } = useA11y();
  return themeVersion === ThemeVersion.Default ? <Styled.Gradient>{children}</Styled.Gradient> : <>{children}</>;
};

const Styled = {
  Gradient: styled.span<{ variant?: 'default' | 'reversed' }>`
    background: ${({ variant = 'default' }) =>
      variant === 'default'
        ? 'linear-gradient(103.18deg, #f6f6f6 -4.11%, #b784d7 106.6%)'
        : 'linear-gradient(103.18deg, #b784d7 -4.11%, #f6f6f6 106.6%)'};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
};
