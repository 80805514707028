import styled from 'styled-components/macro';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useGlobalContext } from 'context';
import { Flex, Button, A11yControls } from 'common/components';
import { SkytradingLogo } from 'assets/SVGs';
import UEBanner from 'assets/Images/Rest/UEBanner.png';
import { NavList } from 'common/components/NavBar/components';

type NavBarDesktopProps = {
  displayLogo?: boolean;
};

export const NavBarDesktop = ({ displayLogo = true }: NavBarDesktopProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { sectionsRefs } = useGlobalContext();

  const navigateWhitelist = () => {
    if (pathname !== '/') {
      navigate('/', { state: { scrollTo: 'waitlist form' } });
    }

    const waitlist = sectionsRefs.find(({ name }) => name === 'waitlist form');

    if (waitlist && waitlist.ref.current) {
      waitlist.ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      waitlist.ref.current.focus();
    }
  };

  return (
    <Styled.Container>
      <Styled.InnerContainer>
        {displayLogo && (
          <Link to="/" aria-label="Go to home page">
            <SkytradingLogo />
          </Link>
        )}
        <NavList />
      </Styled.InnerContainer>
      <Flex gap={16}>
        <Link to="/parp" aria-label="Go to parp page">
          <Styled.Banner src={UEBanner} alt="European found banner" />
        </Link>
        <Button variant="gradient" onClick={navigateWhitelist}>
          Join waitlist
        </Button>
        <A11yControls />
        <Styled.Buttons>
          <a href="https://pl.skytrading.io/" aria-label="Go to Polish version of the site">
            <Button>PL</Button>
          </a>
          <Button variant="primary" disabled>
            ENG
          </Button>
        </Styled.Buttons>
      </Flex>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.nav`
    background-color: rgb(14, 14, 16, 0.05);
    backdrop-filter: blur(8px);
    display: flex;
    width: 100%;
    padding: 18px 6vw 12px 8vw;
    justify-content: space-between;
    align-items: center;
    z-index: ${({ theme }) => theme.zIndexes.layoutBelow};
    position: fixed;
    top: 0;
  `,
  InnerContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 48px;
  `,

  Banner: styled.img`
    max-height: 40px;
  `,

  Buttons: styled.div`
    display: flex;

    button {
      border-radius: 0 5px 5px 0;
    }

    a > button {
      border-radius: 5px 0 0 5px;
    }
  `,
};
