import { FontSize } from 'common/components/A11yControls/types';

const multiplicators = {
  [FontSize.S]: -0.5,
  [FontSize.M]: 0,
  [FontSize.L]: 0.5,
};

export const calculateA11yFontSize = (sizeString: string, fontSize: FontSize) => {
  const baseSize = Number(sizeString.slice(0, -2));
  return `${baseSize + baseSize * multiplicators[fontSize]}px`;
};
