import styled from 'styled-components/macro';

import { Heading, Paragraph, WaitlistForm } from 'common/components';
import { theme } from 'theme';

export const WaitListSection = () => (
  <Styled.Container>
    <Heading level="h3" a11yLevel="h2" align="center">
      Maximize trading profits
    </Heading>
    <Paragraph color={theme.colors.grey[0]} size="xxl">
      Try out strategies before you execute them.
    </Paragraph>
    <WaitlistForm />
  </Styled.Container>
);

const Styled = {
  Container: styled.section`
    width: 80vw;
    background: linear-gradient(306.76deg, rgba(42, 42, 42, 0.32) 49.14%, rgba(75, 75, 75, 0.32) 113.65%);
    padding: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 64px;
  `,
};
